import React, { lazy, Suspense } from "react";
import Cancelable from "react-disposable-decorator";
import { CpNameChip, CpButton, CpTooltip, CpLoader } from "canopy-styleguide!sofe";
import { featureEnabled } from "feature-toggles!sofe";
import { TopnavSecondary } from "primary-navbar!sofe";
import toasts from "toast-service!sofe";
import { userHasOnlyLicense } from "cp-client-auth!sofe";
import { clientQueries } from "clients-ui/externals!sofe";

import { archiveClient, unarchiveClient, deleteClient } from "./clients.resource.js";
import styles from "./client-menu.styles.css";
import Participants from "./participants.component.js";
import { ClientNameDropdown } from "./dropdown.component.js";
import Tabs from "./tabs.component.js";
import { archiveClientFromLocalStorage } from "./recent-clients.helper.js";
import { TopNavHeader } from "./top-nav-header.component.js";
import { NavHeaderKabob } from "./nav-header-kabob.component.js";
import { handleError } from "./error";
import { OldClientDrawerButton } from "./old-client-drawer-button";
import { queryClient } from "./react-query";

const CreateEditClientModal = lazy(async () => {
  const clientsUI = await SystemJS.import("clients-ui!sofe");
  return await clientsUI.getCreateEditClientModal();
});

const AddToClientGroupModal = lazy(async () => {
  const clientsUI = await SystemJS.import("clients-ui!sofe");
  return await clientsUI.getAddToClientGroupModal();
});

class ClientMenu extends React.Component {
  state = {
    clientId: this.props.client.id,
    form: {
      business_name: this.props.client.business_name,
      first_name: this.props.client.first_name,
      last_name: this.props.client.last_name,
      middle_name: this.props.client.middle_name,
    },
    clientType: this.props.client.client_type,
    isEditing: false,
    showClientEditModal: false,
    showAddToClientGroupModal: false,
    showDeleteClientsModal: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.client.id !== prevState.clientId || nextProps.client.client_type !== prevState.clientType) {
      return {
        form: {
          business_name: nextProps.client.business_name,
          first_name: nextProps.client.first_name,
          last_name: nextProps.client.last_name,
        },
        clientType: nextProps.client.client_type,
        isEditing: false,
        showDeleteClientsModal: false,
      };
    } else return null;
  }

  handleFormChange = (property, event) => {
    this.setState({
      form: {
        ...this.state.form,
        [property]: event.target.value,
      },
    });
  };

  showAddToClientGroupModal = () => {
    this.setState({
      showAddToClientGroupModal: true,
    });
  };

  hideAddToClientGroupModal = () => {
    this.setState({
      showAddToClientGroupModal: false,
    });
  };

  showEditModal = () => {
    this.setState({
      showClientEditModal: true,
    });
  };

  hideEditModal = (updated) => {
    if (updated) {
      window.dispatchEvent(new CustomEvent("clients-ui::client-updated"));
    }
    this.setState({
      showClientEditModal: false,
    });
  };

  showDeleteModal = () => {
    this.setState({
      showDeleteClientsModal: true,
    });
  };

  hideDeleteModal = () => {
    this.setState({
      showDeleteClientsModal: false,
    });
  };

  setInputRef = (ref) => {
    this.input = ref;
  };

  startEditing = () => {
    this.setState(
      {
        isEditing: true,
      },
      () => {
        this.input.focus();
        this.input.select();
      }
    );
  };

  stopEditing = () => {
    this.setState({
      isEditing: false,
    });
  };

  cancel = () => {
    this.resetState(this.props);
    this.stopEditing();
  };

  archiveClient = () => {
    archiveClient(this.props.client.id)
      .then(() => {
        toasts.successToast(`Successfully archived ${this.props.client.name}`);

        archiveClientFromLocalStorage(this.props.client.id, this.props.loggedInUser.id);
        window.location = "/#/clients";
      })
      .catch(() => {
        toasts.warningToast("Sorry, we couldn't archive the client. Please try again later.");
      });
  };

  unarchiveClient = () => {
    unarchiveClient(this.props.client.id).subscribe(
      () => {
        toasts.successToast(`Successfully unarchived ${this.props.client.name}`);
        window.dispatchEvent(new CustomEvent("clients-ui::client-updated"));
        this.props.refetchClient();
      },
      (err) => {
        toasts.warningToast("Sorry, we couldn't unarchive the client. Please try again later.");
        handleError(err, {
          showToast: false,
        });
      }
    );
  };

  deleteClient = () => {
    deleteClient(this.props.client.id)
      .then(() => {
        // Remove the client from the query cache before invalidating
        queryClient.removeQueries({
          queryKey: clientQueries.client({ clientId: this.props.client.id.toString() }).queryKey,
        });
        clientQueries.invalidate();
        archiveClientFromLocalStorage(this.props.client.id, this.props.loggedInUser.id);
        window.location = "/#/clients";
      })
      .catch(() => {
        toasts.warningToast("Sorry, we couldn't delete the client. Please try again later.");
      });
  };

  hasAccess = (permission) => {
    return this.props.loggedInUser?.permissions && this.props.loggedInUser?.permissions?.[permission];
  };

  render() {
    const { client, loggedInUser } = this.props;
    const { is_business, business_name, first_name, middle_name, last_name, display_name } = client;
    const name = display_name
      ? display_name
      : is_business // business_name could be set for non-businesses. We want to show first/middle/last for these.
        ? business_name
        : [first_name, middle_name, last_name].filter((i) => i).join(" ");
    const transcriptsOnly = userHasOnlyLicense(loggedInUser, "transcripts");

    const staticName = (
      <div
        className={`${styles.name} ${
          loggedInUser?.role === "TeamMember" ? (transcriptsOnly ? "cp-mt-24" : "cp-mt-12") : "cp-mt-16"
        }`}
      >
        <CpTooltip text={name} disabled={name.length < 25} delay={200} position={"bottom"}>
          <div style={{ maxWidth: "calc(100% - 7rem)" }} className="cps-title cps-pull-left cps-ellipsis ">
            {name}
          </div>
        </CpTooltip>
        {this.hasAccess("clients_archive") || this.hasAccess("clients_create_edit") ? (
          <ClientNameDropdown
            showClientEditModal={this.showEditModal}
            archiveClient={this.archiveClient}
            unarchiveClient={this.unarchiveClient}
            showDeleteModal={this.showDeleteModal}
            client={client}
          />
        ) : null}
        <OldClientDrawerButton loggedInUser={loggedInUser} client={client} refetchClient={this.props.refetchClient} />
      </div>
    );

    const clientTypeValue = this.state.clientType;
    const capitalizedClientType = clientTypeValue.charAt(0).toUpperCase() + clientTypeValue.slice(1);
    const crmHierarchyEnabled = featureEnabled("ft_crm") && this.props.tenant?.crm_status === "crm_hierarchy_complete";

    const clientType = (
      <div
        className={`cps-pull-left ${styles.clientType}${clientTypeValue == "client" ? " cps-color-primary" : ""}${
          clientTypeValue == "prospect" ? " cps-info" : ""
        }${clientTypeValue == "other" ? " cps-gray-10" : ""}`}
      >
        <span
          className={`cps-icon${!is_business ? " cps-icon-client" : ""}${is_business ? " cps-icon-business" : ""}`}
          style={{ color: "inherit" }}
        />
        <span className="cps-caption cps-margin-left-4">{capitalizedClientType}</span>
      </div>
    );

    return (
      <TopnavSecondary className={styles.clientMenu}>
        {crmHierarchyEnabled ? (
          <TopNavHeader
            hasAccess={this.hasAccess}
            client={client}
            loggedInUser={loggedInUser}
            kabob={
              <NavHeaderKabob
                onEdit={this.showEditModal}
                onAddToClientGroup={this.showAddToClientGroupModal}
                onArchive={this.archiveClient}
                onUnarchive={this.unarchiveClient}
                onDelete={this.showDeleteModal}
                client={client}
              />
            }
          />
        ) : (
          <div className="cps-topnav-secondary__content +tall">
            <div className="cps-row">
              <div className="cps-col-xs-8">
                <div>
                  <div className="cps-pull-left cps-margin-top-16 cps-margin-right-24">
                    <CpNameChip size="large" name={name} />
                  </div>

                  <span className="cps-color-primary-text cps-wt-semibold">{staticName}</span>
                  {!transcriptsOnly && loggedInUser.role === "TeamMember" && clientType}
                </div>
              </div>
              <div className="cps-col-xs-4">
                {!transcriptsOnly && this.hasAccess("team_member") && (
                  <Participants hasAccess={this.hasAccess} client={client} />
                )}
              </div>
            </div>
          </div>
        )}
        <Tabs client={client} hasAccess={this.hasAccess} userRole={loggedInUser.role} />
        {this.state.showClientEditModal && (
          <Suspense fallback={<CpLoader />}>
            <CreateEditClientModal
              onModalHide={this.hideEditModal}
              // ft_crm TODO: remove this prop when removing ft_crm feature toggle.
              client={client}
              placeholderClient={client}
              clientId={client.id}
              isCreate={false}
            />
          </Suspense>
        )}
        {this.state.showAddToClientGroupModal && (
          <Suspense fallback={<CpLoader />}>
            <AddToClientGroupModal client={client} onClose={this.hideAddToClientGroupModal} />
          </Suspense>
        )}
        {this.state.showDeleteClientsModal ? (
          <div className="cps-modal">
            <div className="cps-modal__screen" />
            <div className="cps-modal__dialog cps-card__height-3">
              <div className="cps-card__header cps-subheader-sm">
                <span>Delete Client</span>
                <a className="cps-modal__dialog__close cps-icon cps-icon-close" onClick={this.hideDeleteModal} />
              </div>
              <div className="cps-card__body cp-clients-index__modal-body">
                By deleting this client, you will be permanently deleting all billing items, client data, dates,
                resolution cases, files, notes, and tasks related to the client. You will not be able to restore this
                data. Are you sure you want to delete this client?
              </div>
              <hr className="cps-margin-0" />
              <div className="cps-card__body">
                <CpButton btnType="primary" className="cp-mr-12" onClick={this.deleteClient}>
                  Delete client
                </CpButton>
                <CpButton btnType="tertiary" onClick={this.hideDeleteModal}>
                  Cancel
                </CpButton>
              </div>
            </div>
          </div>
        ) : null}
      </TopnavSecondary>
    );
  }
}

export default Cancelable(ClientMenu);
