import React from "react";
import PropTypes from "prop-types";
import canopyUrls from "canopy-urls!sofe";
import { UserTenantProps, userHasOnlyLicense } from "cp-client-auth!sofe";
import { notifyAnalytics } from "./analytics.resource";
import { getActiveTab } from "./tabs.helper";

@UserTenantProps()
export default class Tabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: getActiveTab(this.props.userRole),
    };

    this.boundHandleHashChange = this.handleHashChange.bind(this);
  }

  componentDidMount() {
    window.addEventListener("hashchange", this.boundHandleHashChange);
  }

  componentWillUnmount() {
    window.removeEventListener("hashchange", this.boundHandleHashChange);
  }

  setActiveTab(activeTab, e) {
    this.setState({
      activeTab: activeTab,
    });

    return canopyUrls.navigateToUrl.call(this, e);
  }

  handleHashChange() {
    this.setState({
      activeTab: getActiveTab(),
    });
  }

  navigateToBilling = () => {
    const { client, hasAccess } = this.props;
    if (hasAccess("billing_dashboard")) {
      return `/#/client/${client.id}/billing/dashboard`;
    } else if (hasAccess("billing_invoices")) {
      return `/#/client/${client.id}/billing/invoices`;
    } else if (hasAccess("billing_payments")) {
      return `/#/client/${client.id}/billing/payments/collected`;
    } else if (hasAccess("billing_credits")) {
      return `/#/client/${client.id}/billing/credits`;
    } else if (hasAccess("billing_statements")) {
      return `/#/client/${client.id}/billing/statements`;
    } else if (hasAccess("expense_edit")) {
      return `/#/client/${client.id}/billing/expenses`;
    }
    return;
  };

  render() {
    const { client, hasAccess, userRole, loggedInUser } = this.props;
    const transcriptsOnly = userHasOnlyLicense(loggedInUser, "transcripts");
    return (
      <div className="cps-topnav-secondary__tabs">
        {hasAccess("clients") && (
          <a
            href={"/#/client/" + client.id}
            onClick={this.setActiveTab.bind(this, "home")}
            className={
              this.state.activeTab === "home"
                ? "cps-topnav-secondary__tabs__tab +active"
                : "cps-topnav-secondary__tabs__tab"
            }
          >
            <span>Home</span>
          </a>
        )}
        {userRole === "TeamMember" && hasAccess("clients_email") && (
          <a
            href={"/#/communications/clients/" + client.id}
            onClick={this.setActiveTab.bind(this, "communications")}
            className={
              this.state.activeTab === "communications"
                ? "cps-topnav-secondary__tabs__tab +active"
                : "cps-topnav-secondary__tabs__tab"
            }
          >
            <span>Communication</span>
          </a>
        )}
        {hasAccess("clients") && userRole === "TeamMember" && (
          <a
            href={"/#/client/" + client.id + "/notes"}
            onClick={this.setActiveTab.bind(this, "notes")}
            className={
              this.state.activeTab === "notes"
                ? "cps-topnav-secondary__tabs__tab +active"
                : "cps-topnav-secondary__tabs__tab"
            }
          >
            <span>Notes</span>
          </a>
        )}
        {client.is_active && hasAccess("files_client") && hasAccess("files") && (
          <a
            href={"/#/docs/clients/" + client.id + "/files"}
            onClick={this.setActiveTab.bind(this, "files")}
            className={
              this.state.activeTab === "files"
                ? "cps-topnav-secondary__tabs__tab +active"
                : "cps-topnav-secondary__tabs__tab"
            }
          >
            <span>Files</span>
          </a>
        )}
        {client.is_active && userRole === "TeamMember" && hasAccess("task_dashboard") && (
          <a
            href={"/#/tasks/clients/" + client.id + ""}
            onClick={this.setActiveTab.bind(this, "tasks")}
            className={
              this.state.activeTab === "tasks"
                ? "cps-topnav-secondary__tabs__tab +active"
                : "cps-topnav-secondary__tabs__tab"
            }
          >
            <span>{transcriptsOnly ? "Notices" : "Tasks"}</span>
          </a>
        )}
        {client.is_active && hasAccess("tasks_resolution_cases") && (
          <a
            href={"/#/client/" + client.id + "/resolution-cases/list/active"}
            onClick={this.setActiveTab.bind(this, "resolution-cases")}
            className={
              this.state.activeTab === "resolution-cases"
                ? "cps-topnav-secondary__tabs__tab +active"
                : "cps-topnav-secondary__tabs__tab"
            }
          >
            <span>Resolution Cases</span>
          </a>
        )}
        {client.is_active && hasAccess("tasks_organizers") && (
          <a
            href={`/#/tax-prep-tasks/clients/${client.id}`}
            onClick={this.setActiveTab.bind(this, "tax-returns")}
            className={
              this.state.activeTab === "tax-returns"
                ? "cps-topnav-secondary__tabs__tab +active"
                : "cps-topnav-secondary__tabs__tab"
            }
          >
            <span>Organizers</span>
          </a>
        )}
        {client.is_active && hasAccess("transcripts") && userRole === "TeamMember" && (
          <a
            href={`/#/client/${client.id}/transcripts/reports`}
            onClick={(e) => {
              this.setActiveTab("transcripts", e);
              notifyAnalytics(
                this.props.loggedInUser,
                "transcripts",
                "transcripts",
                "transcripts.client_level_tab_clicked"
              );
            }}
            className={
              this.state.activeTab === "transcripts"
                ? "cps-topnav-secondary__tabs__tab +active"
                : "cps-topnav-secondary__tabs__tab"
            }
          >
            <span>Transcripts</span>
          </a>
        )}
        {client.is_active && hasAccess("client_billing") && (
          <a
            href={"/#/client/" + client.id + "/billing"}
            onClick={this.setActiveTab.bind(this, "payments")}
            className={
              this.state.activeTab === "payments"
                ? "cps-topnav-secondary__tabs__tab +active"
                : "cps-topnav-secondary__tabs__tab"
            }
          >
            <span>Payments</span>
          </a>
        )}
        {client.is_active && this.navigateToBilling() && (
          <a
            href={this.navigateToBilling()}
            onClick={this.setActiveTab.bind(this, "billing")}
            className={
              this.state.activeTab === "billing"
                ? "cps-topnav-secondary__tabs__tab +active"
                : "cps-topnav-secondary__tabs__tab"
            }
          >
            <span>Billing</span>
          </a>
        )}
        {client.is_active && hasAccess("time_tracking") && (
          <a
            href={`/#/client/${client.id}/time/entries`}
            onClick={this.setActiveTab.bind(this, "billing")}
            className={
              this.state.activeTab === "time-entries"
                ? "cps-topnav-secondary__tabs__tab +active"
                : "cps-topnav-secondary__tabs__tab"
            }
          >
            <span>Time Entries</span>
          </a>
        )}
        {userRole === "Client" && hasAccess("client_tax_resolution") && (
          <a
            href={"/#/client/" + client.id + "/requests"}
            onClick={this.setActiveTab.bind(this, "requests")}
            className={
              this.state.activeTab === "requests"
                ? "cps-topnav-secondary__tabs__tab +active"
                : "cps-topnav-secondary__tabs__tab"
            }
          >
            <span>Requests</span>
          </a>
        )}
      </div>
    );
  }
}

Tabs.propTypes = {
  client: PropTypes.object,
  hasAccess: PropTypes.func,
  userRole: PropTypes.string,
};
